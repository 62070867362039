import { graphql } from "gatsby"
import React from "react"
import { Container } from "react-bootstrap"
import ImageBanner from "../components/ImageBanner"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

const Shs = ({ data }) => {
  const { strapiSustainabilityHealthSecurityPage: info } = data

  return (
    <Layout>
      <Seo Sitetitle="Sustainability,Health & Security" />
      <ImageBanner
        heading={info.banner_heading}
        img={info.banner_img.url}
        para={info.banner_description}
      />
      <Container className="my-5 text-center">
        <h2>{info.info_heading}</h2>
        <p className="my-4 h5">{info.info_description}</p>
        <iframe
          width="460"
          height="515"
          src={info.youtube_embed_link}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="my-3"
        ></iframe>
      </Container>
    </Layout>
  )
}

export const data = graphql`
  {
    strapiSustainabilityHealthSecurityPage {
      banner_heading
      banner_description
      banner_img {
        url
      }
      info_heading
      info_description
      youtube_embed_link
    }
  }
`

export default Shs
